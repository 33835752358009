.buttonlink{
  padding: 10px 10px;
  margin: 10px 10px;
  text-decoration: none;
  border: none;
  background-color: white;
  color: black;
  font-size: 1em;
  border-radius: 5px;
 
}
.buttonlink:hover{
  background-color: rgb(233, 230, 230);
  transition: 0.5s;
  cursor: pointer;
}
html,body,#root{
  background-color: #F4F4F4;
}

.grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -10px; /* gutter size offset */
  width: auto;
}
.itemgrid {
  padding-left: 10px; /* gutter size */
  background-clip: padding-box;
}